@mixin flex($direction, $align-items, $justify-content) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin input-message() {
  font-size: 0.8rem;
  box-sizing: border-box;
  padding: 2px 13px;
  animation: inputMessageAnimation 0.3s;
}
