/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@import "mixins";
@tailwind utilities;

.card {
  @apply p-4 bg-card shadow rounded-xl border border-gray-300 overflow-hidden;
}

.input-error {
  @include input-message();
  @apply text-red-500;
}
